import React from "react"
import { graphql } from "gatsby"

import GamesLayout from '../components/GamesLayout';
import useMatchedQuery from '../hooks/useMatchedQuery';
import EventDisplay from "../components/EventDisplay";
import EventData from "../StructureData/EventData";

export default function Event(props) {
  const {event} = props.data
  const matchedQuery = useMatchedQuery()
  const { ...context } = props.pageContext

  return (
    <GamesLayout {...context} matchedQuery={matchedQuery} useHeader={true} useContainer={true} withSearch={false} title={event.title}>
      <EventData event={event} />
      <EventDisplay event={event} matchedQuery={matchedQuery} />
    </GamesLayout>
  )
}
// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.
// olivos-1656633600000-3cKktgmAcDpgx7gmjfql2d
export const query = graphql`
  query($id: String) {
    event(id: { eq: $id }) {
      title
      date
      sourceId
      day
      description
      gallery
      icon
      id
      landscapeImage
      name
      portraitImage
      priceInCents
      sourceId
      squareImage
      startDate
      endDate
      published
      url
      plainTextDescription
      location {
        avatar
        name
        address
        googleMapsLink
        publicTransport
        description
        gallery
        streetAddress
        addressLocality
        addressRegion
        zipCode
        addressCountry
        shortDescription
      }
      details {
        dateInMiliseconds
        startTime
        endTime
        durationInHours
        cancellationFreeBeforeInHours
      }
    }
  }
`